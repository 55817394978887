import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { PERSISTENT_STATES_REGISTRY } from '@data/persistent.states.registry';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@env/environment';
import { STORE_COMMON_STORE_ITEM_NAME } from '@core/store/store.const';
import { AppPersistedStateModel } from './state/app.models';
import { convertDateStringFormatsToDate } from '@core/misc/misc.utils';
import { AppState } from './state/app.state';
import { AppPersistedState } from './state/app.persisted.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

const devImports = [];

if (!environment.production) {
  devImports.push(NgxsReduxDevtoolsPluginModule.forRoot());
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,

    //NGXS
    NgxsModule.forRoot([AppState, AppPersistedState], {
      developmentMode: !environment.production
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      keys: PERSISTENT_STATES_REGISTRY,
      afterDeserialize: (obj) => convertDateStringFormatsToDate(obj),
      migrations: [
        {
          version: environment.persistedStateStorageVersion - 1,
          versionKey: '_version',
          key: STORE_COMMON_STORE_ITEM_NAME,
          migrate: () => new AppPersistedStateModel()
        }
      ]
    }),
    ...devImports,
    /*  */
    CoreModule,
    HammerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
