import { LOCAL_STORAGE_ENGINE, SESSION_STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { AviaState } from '@pages/avia-page/state/avia.state';
import { HotelsState } from '@pages/hotels-page/state/hotels.state';
import { ToursState } from '@pages/tours-page/state/tours.state';
import { CartState } from '@shared/modules/cart/state/cart.state';
import { AppPersistedState } from '../state/app.persisted.state';
import { StorageKey } from '@ngxs/storage-plugin/internals';

export const PERSISTENT_STATES_REGISTRY: StorageKey[] = [
  { engine: LOCAL_STORAGE_ENGINE, key: 'auth.refreshToken' },
  { engine: LOCAL_STORAGE_ENGINE, key: 'auth.sessionExpiredAt' },
  { engine: SESSION_STORAGE_ENGINE, key: CartState },
  //
  { engine: SESSION_STORAGE_ENGINE, key: ToursState },
  { engine: SESSION_STORAGE_ENGINE, key: HotelsState },
  { engine: SESSION_STORAGE_ENGINE, key: AviaState },
  AppPersistedState
];
