import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { template } from 'lodash-es';

export function HttpTranslationsLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locales/', '.json?v' + environment.version);
}

export class TranslateHandler implements MissingTranslationHandler {
  handle({ key, interpolateParams, translateService }: MissingTranslationHandlerParams) {
    const withExtractedParams = this.extractParamsFromDataTag(key);
    if (withExtractedParams) return translateService.instant(withExtractedParams.key, withExtractedParams.params);
    if (interpolateParams) return template(key, { interpolate: /{{([\s\S]+?)}}/g })(interpolateParams);

    return key;
  }
  private extractParamsFromDataTag(originalString: string) {
    const dataStartIndex = originalString.indexOf('<data>');
    const dataEndIndex = originalString.lastIndexOf('</data>');
    if (dataEndIndex < 0) return false;
    const key = originalString.substring(0, dataStartIndex);

    const jsonData = originalString.substring(dataStartIndex + '<data>'.length, dataEndIndex).replace(/'/g, '"');
    try {
      const params = JSON.parse(jsonData);
      return { key, params };
    } catch (error) {
      console.error("Can't parse params from locale string data tag", { originalString, error });
      return { key, params: {} };
    }
  }
}
