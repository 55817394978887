import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { authLogOut, authVerifyAccessToken } from '@core/modules/auth/state/auth.actions';
import { AuthState } from '@core/modules/auth/state/auth.state';
import { StoreService } from '@core/store/store.service';
import { LOG_IN_PAGE } from '@data/app.data';
import { environment } from '@env/environment';
import { catchError, filter, map, switchMap, take, throwError } from 'rxjs';
import { AppPersistedState } from 'src/app/state/app.persisted.state';

export class ApiErrorResponse<T = unknown> {
  public status?: number;
  public headers?: HttpHeaders;
  public url?: string;
  public statusText?: string;
  public error?: T;
  public body?: T;
  public sysErrorMessage?: string;
  public displayErrorMessage?: string;
  public errorCode?: string;
  public activityId?: string;
  constructor(init: ApiErrorResponse<T>) {
    Object.assign(this, init);
  }
}

export const ApiInterceptor: HttpInterceptorFn = (request, next) => {
  const storeService = inject(StoreService);

  if (
    !request.url.includes(environment.urls.api) ||
    (request.url.includes('/auth/') && !request.url.includes('ChangePassword'))
  )
    return next(request);
  return storeService
    .dispatchAndSelect$(new authVerifyAccessToken(), AuthState.refreshTokenLoadStatus)
    .pipe(
      filter((e) => e?.status !== 'loading'),
      take(1)
    )
    .pipe(
      map(() => {
        const accessToken = storeService.selectSnapshot(AuthState.accessToken);
        let Accept = ['application/json'];
        if (request.url.endsWith('svg')) Accept = ['image/svg+xml'];
        const setHeaders: Record<string, string | string[]> = {
          Accept,
          'Accept-Language': storeService.selectSnapshot(AppPersistedState.language)
        };
        if (accessToken) setHeaders['Authorization'] = `Bearer ${accessToken}`;
        request = request.clone({
          setHeaders,
          withCredentials: true
        });
        return request;
      }),
      switchMap((r) =>
        next(r).pipe(
          map((event) => {
            if (event instanceof HttpResponse && event.ok) {
              const nugiosResultSuccess = event.headers.get('X-Nugios-Result-Success')?.toLowerCase();
              if (nugiosResultSuccess === 'false') {
                throw new HttpErrorResponse({
                  status: 500,
                  headers: event.headers,
                  url: event.url ?? '',
                  statusText: event.statusText,
                  error: event.body
                });
              }
            }
            return event;
          }),
          catchError((e: HttpErrorResponse) => {
            if (e.status === 401)
              if (!window.location.pathname.includes(LOG_IN_PAGE)) storeService.dispatch(new authLogOut(true));
            const headerErrorMessage = e?.headers?.get('X-Nugios-Result-Error');
            const statusTextFix = e?.statusText?.toLowerCase() === 'ok' ? undefined : e?.statusText;
            const displayErrorMessage = e?.error?.userErrorDescription;
            const sysErrorMessage =
              e?.error?.errorDescription ??
              (headerErrorMessage ? decodeURI(headerErrorMessage) : statusTextFix ?? e.message ?? 'Unknown');
            const headerErrorCode = e?.headers?.get('X-Nugios-Result-Error-Code');
            const errorCode = headerErrorCode ?? e.name;
            const activityId = e?.error?.activityId;
            const apiErrorResponse = new ApiErrorResponse({
              errorCode,
              displayErrorMessage,
              sysErrorMessage,
              activityId,
              ...(e as ApiErrorResponse)
            });
            return throwError(() => apiErrorResponse);
          })
        )
      )
    );
};
