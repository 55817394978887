import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { UserState } from '@core/modules/user/state/user.state';
import { StoreService } from '@core/store/store.service';
import { HEADER_MENU } from '@data/menu.data';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private menuData = HEADER_MENU;

  constructor(
    private router: Router,
    private title: Title,
    private translateService: TranslateService,
    private googleTagManagerService: GoogleTagManagerService,
    private storeService: StoreService
  ) {}

  public init() {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((route) => {
      const routeUrl = (route as NavigationEnd).url;

      let pageName = null;
      if (routeUrl) {
        const activatedPage = this.menuData.find((e) => routeUrl.includes(e.path.substring(1)));
        if (activatedPage?.label)
          pageName = `Kazunion Dashboard | ${this.translateService.instant(activatedPage.label)}`;
      }

      //GTM
      let tagData: Record<string, unknown> = {
        event: 'page',
        pageName,
        pageUrl: routeUrl
      };
      const userData = this.storeService.selectSnapshot(UserState.userData);
      if (userData)
        tagData = {
          ...tagData,
          userId: userData.UserId,
          userEmail: userData.Email,
          userName: userData.UserName,
          userFullName: userData.FullName
        };
      this.googleTagManagerService.pushTag(tagData);
      //
      this.title.setTitle(pageName ?? 'Kazunion Dashboard');
    });
  }
}
