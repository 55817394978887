import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { addMinutes } from 'date-fns';
import { Subscription, timer } from 'rxjs';
import { ConfirmService } from '../../../shared/modules/confirm/confirm.service';
import { authVerifyAccessToken } from '../auth/state/auth.actions';
import { AuthState } from '../auth/state/auth.state';
import { SessionCheckComponent } from './session-check.component';

@Injectable()
export class SessionCheckService {
  private timerSubscription: Subscription | null = null;
  constructor(
    private confirmService: ConfirmService,
    private storeService: StoreService
  ) {}
  public init() {
    this.runUserActivityListener();
    this.runVisibilityListener();
  }

  private runVisibilityListener() {
    document.addEventListener(
      'visibilitychange',
      () => document.visibilityState === 'visible' && this.storeService.dispatch(new authVerifyAccessToken())
    );
  }

  private runUserActivityListener() {
    this.storeService.select(AuthState.tokensUpdatedAt).subscribe((tokensUpdatedAt) => {
      this.clearSubscription();
      if (tokensUpdatedAt) {
        this.timerSubscription = timer(addMinutes(tokensUpdatedAt, environment.sessionTimer - 1)).subscribe(() =>
          this.openSessionEndingModal()
        );
      }
    });
  }

  private clearSubscription() {
    if (this.timerSubscription?.unsubscribe) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }
  private openSessionEndingModal() {
    this.confirmService.confirm<SessionCheckComponent>(
      {
        title: 'Session Ending Soon',
        componentPortal: new ComponentPortal(SessionCheckComponent)
      },
      {
        icon: 'timer',
        backDropClose: true
      },
      {
        footerStyleClass: 'confirmation-footer-center-actions',
        primaryButton: {
          label: 'Refresh Session',
          handler: () => {
            this.storeService.dispatch(new authVerifyAccessToken(true));
          }
        }
      }
    );
  }
}
