import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { KzDesktopMultiSelectBoxComponent } from '@shared/components/desktop/kz-desktop-multi-select-box/kz-desktop-multi-select-box.component';
import { TypedTemplateDirective } from '@shared/directives/typed-template.directive';
import { DynamicModalModule } from '@shared/modules/dynamic-modal/dynamic-modal.module';
import { ResponseLoggerComponent } from './response-logger.component';
import { responseLoggerInterceptor } from './response-logger.interceptor';
import { ResponseLoggerState } from './state/response-logger.state';
import { SecondsCounterPipe } from '@shared/pipes/seconds-counter.pipe';
import { KzMobileMultiSelectBoxComponent } from '@shared/components/mobile/kz-mobile-multi-select-box/kz-mobile-multi-select-box.component';

@NgModule({
  declarations: [ResponseLoggerComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([ResponseLoggerState]),
    DynamicModalModule,
    KzDesktopMultiSelectBoxComponent,
    TypedTemplateDirective,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    SecondsCounterPipe,
    KzMobileMultiSelectBoxComponent
  ],
  providers: [provideHttpClient(withInterceptors([responseLoggerInterceptor]))]
})
export class ResponseLoggerModule {}
