import { inject } from '@angular/core';
import { CanActivateChildFn } from '@angular/router';
import { AuthState } from '@core/modules/auth/state/auth.state';
import { StoreService } from '@core/store/store.service';
import { Navigate } from '@ngxs/router-plugin';

export const passwordExpiredGuard: CanActivateChildFn = (route, state) => {
  const storeService = inject(StoreService);
  const passwordExpired = storeService.selectSnapshot(AuthState.passwordExpired);
  if (!(state.url || '').includes('change-password') && passwordExpired) {
    storeService.dispatch(new Navigate(['/change-password']));
    return false;
  }
  return true;
};
