import { Component, OnInit, signal } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { bounceInOnEnterAnimation } from 'angular-animations';
import { authLogOut } from '../auth/state/auth.actions';

@Component({
  selector: 'app-session-check',
  templateUrl: './session-check.component.html',
  styleUrl: './session-check.component.scss',
  animations: [bounceInOnEnterAnimation()]
})
export class SessionCheckComponent implements OnInit {
  public seconds = signal(10);
  public countDownTimer?: ReturnType<typeof setInterval>;
  constructor(private storeService: StoreService) {}
  ngOnInit(): void {
    this.initCountDown();
  }

  private initCountDown() {
    this.countDownTimer = setInterval(
      () =>
        this.seconds.update(() => {
          const seconds = this.seconds();
          if (seconds > 0) return seconds - 1;
          clearInterval(this.countDownTimer);
          this.logOut();
          return 0;
        }),
      1000
    );
  }
  private logOut() {
    this.storeService.dispatch(new authLogOut(true));
  }
}
