import { HttpInterceptorFn } from '@angular/common/http';
import { dateFormatRegExpWithZone } from '@core/misc/misc.utils';
import { environment } from '@env/environment';
import { formatISO } from 'date-fns';

const formatOutgoingDate = (data?: Record<string, unknown> | null): unknown => {
  if (data === null || data === undefined) return data;
  if (typeof data !== 'object') return data;

  Object.keys(data).forEach((key: string) => {
    const value = data[key];
    if (
      value instanceof Date ||
      (typeof value === 'string' && value.length > 0 && dateFormatRegExpWithZone.test(value))
    )
      data[key] = formatISO(value).match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)![0];
    else if (typeof value === 'object') formatOutgoingDate(value as Record<string, unknown>);
  });
  return data;
};

export const DateInterceptor: HttpInterceptorFn = (request, next) => {
  if (request.url.includes(environment.urls.api))
    if (typeof request.body === 'object' || Array.isArray(request.body))
      request = request.clone({
        body: formatOutgoingDate(JSON.parse(JSON.stringify(request.body)) as Record<string, unknown>)
      });
  return next(request);
};
