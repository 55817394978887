import { Injectable } from '@angular/core';
import { authInit } from '@core/modules/auth/state/auth.actions';
import { SessionCheckService } from '@core/modules/session-check/session-check.service';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { aviaReset } from '@pages/avia-page/state/avia.actions';
import { hotelsReset } from '@pages/hotels-page/state/hotels.actions';
import { toursReset } from '@pages/tours-page/state/tours.actions';
import { appInit, appSetSearchByUrlModule } from 'src/app/state/app.actions';
import { DeviceService } from './device.service';
import { LocaleService } from './locale.service';
import { SvgIconsService } from './svg-icons.service';
import { TitleService } from './title.service';
import { Navigate } from '@ngxs/router-plugin';
import { NGXS_TOURS_SEARCH_FORM } from '@pages/tours-page/tours-page.const';
import { NGXS_HOTELS_SEARCH_FORM } from '@pages/hotels-page/hotels-page.const';
import { NGXS_AVIA_SEARCH_FORM } from '@pages/avia-page/avia-page.const';
import { UpdateFormValue } from '@ngxs/form-plugin';

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  constructor(
    private localeService: LocaleService,
    private svgIconsService: SvgIconsService,
    private deviceService: DeviceService,
    private storeService: StoreService,
    private titleService: TitleService,
    private sessionCheckService: SessionCheckService
  ) {}

  public run() {
    this.titleService.init();
    this.deviceService.init();
    this.svgIconsService.init();
    this.localeService.init(environment.lang);
    this.sessionCheckService.init();
    this.checkSearchByUrlParams();
    this.runStoreActions();
    if (environment.resetStatesBeforeUnload) this.resetStatesBeforeUnload();
  }

  private runStoreActions() {
    this.storeService.dispatch([new authInit(), new appInit()]);
  }

  private resetStatesBeforeUnload() {
    window.addEventListener('unload', () =>
      this.storeService.dispatch([new toursReset(), new hotelsReset(), new aviaReset()])
    );
  }
  private checkSearchByUrlParams() {
    const params = new URLSearchParams(window.location.search);
    const search = params.get('search');
    const base64Payload = params.get('base64Payload');
    if (search && base64Payload) {
      if (!['tours', 'hotels', 'avia'].includes(search)) {
        console.error('Wrong search module', { search, base64Payload });
        this.storeService.dispatch(new Navigate(['/'], { search: null, base64Payload: null }));
        return;
      }
      try {
        const payload = JSON.parse(atob(base64Payload));
        let ngxsForm: string | null = null;

        if (search === 'tours') ngxsForm = NGXS_TOURS_SEARCH_FORM;
        else if (search === 'hotels') ngxsForm = NGXS_HOTELS_SEARCH_FORM;
        else if (search === 'avia') ngxsForm = NGXS_AVIA_SEARCH_FORM;

        if (ngxsForm) {
          this.storeService.dispatch(new UpdateFormValue({ path: ngxsForm, value: payload }));
        }
        this.storeService.dispatch(new appSetSearchByUrlModule(search as 'tours' | 'hotels' | 'avia'));
      } catch (error) {
        console.error("Can't parse `base64Payload` from url search params", { search, base64Payload });
      }
      this.storeService.dispatch(new Navigate([search], { search: null, base64Payload: null }));
    }
    return;
  }
}
