import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DEVICE, logger } from '@core/misc/misc.utils';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta
  ) {}

  public init(): Promise<boolean> {
    return new Promise((resolve) => {
      logger?.info('⚙️ applying configurations by device...');
      const style = DEVICE.any() ? 'mobile.css' : 'desktop.css';
      if (DEVICE.any()) {
        this.meta.addTag({
          name: 'viewport',
          content:
            'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no,shrink-to-fit=no'
        });
        this.meta.addTag({ name: 'orientation', content: 'portrait' });
        if (DEVICE.iOS()) {
          this.meta.addTag({
            name: 'apple-mobile-web-app-capable',
            content: 'yes'
          });
          this.meta.addTag({
            name: 'apple-mobile-web-app-title',
            content: 'Kazunion'
          });
          /* @toDo - no icon for that
          this.meta.addTag({
            name: 'apple-touch-icon',
            content: 'favicon.ico'
          });
          */
        } else if (DEVICE.Android()) this.inputFocusScrollFix();
      }
      const themeLink: HTMLLinkElement = this.document.createElement('link');
      themeLink.type = 'text/css';
      themeLink.rel = 'stylesheet';
      themeLink.href = style + '?v' + environment.version;
      //to set theme-color
      themeLink.onload = () => {
        const themeColor = getComputedStyle(this.document.documentElement).getPropertyValue('--theme-color');
        if (themeColor)
          this.meta.addTag({
            name: 'theme-color',
            content: themeColor.replace(/\s/g, '')
          });
        resolve(true);
      };
      this.document.head.appendChild(themeLink);
    });
  }

  private inputFocusScrollFix() {
    logger?.info('🐞 run `focusin` listener to fix scroll to input bug on android devices...');
    this.document.body.addEventListener('focusin', () => {
      if (this.document.activeElement && this.document.activeElement.tagName === ('INPUT' || 'TEXTAREA')) {
        setTimeout(
          () =>
            this.document.activeElement?.scrollIntoView({
              block: 'center',
              behavior: 'smooth'
            }),
          100
        );
      }
    });
  }
}
