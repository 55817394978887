import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmModule } from '@shared/modules/confirm/confirm.module';
import { DynamicModalModule } from '@shared/modules/dynamic-modal/dynamic-modal.module';
import { SessionCheckComponent } from './session-check.component';
import { SessionCheckService } from './session-check.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SessionCheckComponent],
  imports: [CommonModule, DynamicModalModule, MatIconModule, ConfirmModule, TranslateModule],
  providers: [SessionCheckService]
})
export class SessionCheckModule {}
