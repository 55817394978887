import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@core/modules/auth/auth.guard';
import { passwordExpiredGuard } from '@core/modules/auth/password-expired.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    canActivateChild: [passwordExpiredGuard],
    loadChildren: () => import('@pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: '**',
    loadChildren: () => import('@pages/error-pages/error-pages.module').then((m) => m.ErrorPagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
