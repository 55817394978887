import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { tap } from 'rxjs';
import { responseLoggerSave } from './state/response-logger.actions';

export const responseLoggerInterceptor: HttpInterceptorFn = (request, next) => {
  if (
    !environment.responseLogger?.trackRegExp?.test(request.url) ||
    environment?.responseLogger?.ignoreRegExp?.test(request.url)
  )
    return next(request);
  const requestAt = new Date();
  const storeService = inject(StoreService); // inject the logger service
  const id = crypto.randomUUID();
  let responseStatus: number | null = null;
  storeService.dispatch(new responseLoggerSave(id, 'progress', requestAt, request));
  return next(request).pipe(
    tap({
      next: (response) => {
        if (response instanceof HttpResponse) {
          storeService.dispatch(new responseLoggerSave(id, 'success', requestAt, request, new Date(), response));
          responseStatus = response.status;
        }
      },
      error: (response: HttpResponse<unknown> | HttpErrorResponse) => {
        responseStatus = response.status;
        storeService.dispatch(new responseLoggerSave(id, 'error', requestAt, request, new Date(), response));
      },

      finalize: () => {
        if (responseStatus === null)
          storeService.dispatch(new responseLoggerSave(id, 'cancel', requestAt, request, new Date()));
      }
    })
  );
};
