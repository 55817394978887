import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { Navigate } from '@ngxs/router-plugin';
import { filter, map, switchMap, take } from 'rxjs';
import { authVerifyAccessToken } from './state/auth.actions';
import { AuthState } from './state/auth.state';

export const authGuard: CanActivateFn = (route, state) => {
  const storeService = inject(StoreService);
  return storeService.dispatchAndSelect$(new authVerifyAccessToken(), AuthState.refreshTokenLoadStatus).pipe(
    filter((e) => e?.status !== 'loading'),
    take(1),
    switchMap(() => storeService.select(AuthState.isAuthenticated)),
    map((isAuthenticated) => {
      if (isAuthenticated) {
        if ((state.url || '').includes('log-in')) storeService.dispatch(new Navigate(['/']));
        return true;
      }
      if ((state.url || '').includes('log-in')) return true;
      else storeService.dispatch(new Navigate(['/log-in']));
      return false;
    })
  );
};
