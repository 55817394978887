import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HotelsPageModule } from '@pages/hotels-page/hotels-page.module';
import { ToursPageModule } from '@pages/tours-page/tours-page.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { HammerConfig } from './misc/hammer.config';
import { HttpTranslationsLoaderFactory, TranslateHandler } from './misc/locale.utils';
import { AuthModule } from './modules/auth/auth.module';
import { MaterialConfigModule } from './modules/material-config.module';
import { ResponseLoggerModule } from './modules/response-logger/response-logger.module';
import { SessionCheckModule } from './modules/session-check/session-check.module';
import { UserModule } from './modules/user/user.module';
import { StartupService } from './services/startup.service';
import { SvgIconsService } from './services/svg-icons.service';
import { DateInterceptor } from './interceptors/date.interceptor';

const sharedModules = [AuthModule, UserModule, MaterialConfigModule];

@NgModule({
  declarations: [],
  exports: [...sharedModules],
  imports: [
    CommonModule,
    SessionCheckModule,
    ResponseLoggerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslationsLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: [{ provide: MissingTranslationHandler, useClass: TranslateHandler }],
      extend: true
    }),
    GoogleTagManagerModule.forRoot({
      id: 'G-LL7GDMBLKE'
    }),
    //Temporary solution - to be able search by url
    ToursPageModule,
    HotelsPageModule,
    ...sharedModules
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (startupService: StartupService) => () => startupService.run(),
      deps: [StartupService],
      multi: true
    },
    provideHttpClient(withInterceptors([ApiInterceptor, DateInterceptor])),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: environment.formats.date } },
    StartupService,
    SvgIconsService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {}
